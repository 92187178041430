import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import axios from 'axios'
import 'bootstrap'
import Toaster from '@meforma/vue-toaster'
import dayjs from 'dayjs'
import { createYmaps } from 'vue-yandex-maps'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import VueAwesomePaginate from 'vue-awesome-paginate'
import VueClickAway from 'vue3-click-away'
import { getCookie } from '@/utilits/getCookie'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'

dayjs.extend(customParseFormat)
const baseUrl = 'https://sfapi.wfstudio.ru'

axios.defaults.baseURL = baseUrl

const api = axios.create({
  baseURL: 'https://sfapi.wfstudio.ru',
  headers: {
    'Content-Type': 'multipart/form-data'
  }
})

try {
  api.interceptors.request.use((config) => {
    const token = getCookie('sfa_user_token')
    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }
    return config
  })
} catch (error) {
  router.push({ name: 'auth' })
}


export default api

const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)
const app = createApp(App)
app.use(pinia)
app.use(createYmaps({
  apikey: '30fc6a2a-feb5-4396-bb95-230d41586d8a'
}))
app.use(router)
app.use(VueClickAway)
app.use(VueAwesomePaginate)
app.provide('dayjs', dayjs)
app
  .use(Toaster, { position: 'top-right' })
  .provide('toast', app.config.globalProperties.$toast)

if (process.env.NODE_ENV === 'development') {
  app.mount('#app')
} else {
  document.addEventListener('deviceready', function () {
    app.mount('#app')
  })
}

document.addEventListener('gesturestart', (event) => {
  event.preventDefault()
})
