import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import axios from 'axios'
import { useRouter } from 'vue-router'
import { getCookie } from '@/utilits/getCookie'
import { deleteCookieToken } from '@/utilits/deleteCookieToken'
import { getFirebaseToken } from '@/utilits/getFirebaseToken'

export const useUserStore = defineStore('user', () => {
  const user = ref(null)
  const userToken = ref(null)
  const userPosition = ref(null)
  const currentUserName = ref(null)
  const router = useRouter()
  const userTokenStorage = computed(() => {
    return getCookie('sfa_user_token')
  })

  const getUserId = computed(() => {
    if (!user.value) return null

    return user.value.id
  })

  const getUserPositionId = computed(() => {
    if (userPosition.value) {
      return userPosition.value
    } else if (!user.value) return null
    else {
      return user.value.position_id
    }
  })

  const hasRights = computed(() => {
    if (user.value) {
      for (const key in user.value.roles) {
        if (key === 'admin' || key === 'taskAdmin' || key === 'leader') {
          return true
        }
      }
      return false
    }
    return false
  })

  const hasTaskPermission = computed(() => {
    if (user.value) {
      // Temporary hardcoded rights checking for predefined list of positionId. 
      // return [373].some(el => el === user.value?.position_id)
      return user.value?.permissions?.task ?? false
    }
    return false
  })

  const isAdmin = computed(() => {
    if (user.value) {
      for (const key in user.value.roles) {
        if (key === 'admin') {
          return true
        }
      }
      return false
    }
    return false
  })

  const userFullName = computed(() => {
    if (currentUserName.value) {
      return currentUserName.value
    } else if (!user.value) return null
    else {
      return `
    ${user.value.first_name}
    ${user.value.last_name}
    `
    }
  })
  const loadUserFromSession = () => {
    const storedPosition = sessionStorage.getItem('userPosition')
    const storedName = sessionStorage.getItem('currentUserName')
    if (storedPosition) {
      userPosition.value = storedPosition
    }
    if (storedName) {
      currentUserName.value = storedName
    }
  }
  const setUserToken = (token) => {
    const twoWeeksInMs = 3600 * 24 * 14
    document.cookie = `sfa_user_token=${token}; max-age=${twoWeeksInMs}; path=/; secure`
    window.localStorage.setItem('sfa_user_token', token)
    userToken.value = token
  }

  const setUserPosition = (positionId, lastName, firstName) => {
    sessionStorage.setItem('userPosition', positionId)
    sessionStorage.setItem('currentUserName', `${lastName} ${firstName}`)
    userPosition.value = positionId
    currentUserName.value = `${lastName} ${firstName}`
  }

  const logOut = () => {
    deleteCookieToken()
    userToken.value = null
    user.value = null
    router.push({
      name: 'auth'
    })
  }
  const setUser = async (token) => {
    try {
      const response = await axios.get('/v2/user', {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })

      if (response.data.success === true) {
        user.value = { ...response.data.data }
        await getFirebaseToken()
      }
    } catch (err) {
      router.push({
        name: 'auth'
      })
      throw new Error(err)
    }
  }

  // While we have no separate store for tasks, I'll keep it here.
  const copyOfTaskData = ref(null);

  return {
    user,
    setUserToken,
    userToken,
    setUser,
    userTokenStorage,
    logOut,
    getUserId,
    userFullName,
    getUserPositionId,
    setUserPosition,
    hasRights,
    loadUserFromSession,
    userPosition,
    isAdmin,
    hasTaskPermission,
    copyOfTaskData,
  }
},
{
  persist: {
    key: 'sfa_user_store',
    pick: ['copyOfTaskData'],
  }
}
)
