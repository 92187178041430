<script setup>
import { ref } from 'vue';

const props = defineProps(
  {
    listData: {
      type: [Object, Array, null],
      required: true,
      default: null,
    },
    countTotal: {
      type: [Number, null],
      required: true,
      default: 0
    },
    initialOnPageNumber: {
      type: [Number, null],
      required: false,
      default: 10
    }
  }
);

const emit = defineEmits(
  [
    'change-on-page-number',
  ]
);

const selectedPageModel = defineModel(
  'selectedPage',
  {
    type: [Number, null],
  }
);

const onPageOptions = [
  {
    count: 5,
    value: 5
  },
  {
    count: 10,
    value: 10
  },
  {
    count: 15,
    value: 15
  },
  {
    count: 'Все',
    value: -1
  }
]

const onPageNumber = ref(props.initialOnPageNumber);

function changeOnPageNumber(entriesNumber) {
  emit('change-on-page-number', entriesNumber)
}
</script>

<template>
  <div
    v-if="listData && (countTotal > 5)"
    class="data-list__pagination"
  >
    <div class="table-footer__select">
      <span>Показать на странице: </span>
      <div class="count-list">
        <ul>
          <li
            v-for="item in onPageOptions"
            :key="item.value"
            :class="{ active: item.value === onPageNumber }"
            @click="onPageNumber = item.value; changeOnPageNumber(item.value)"
          >
            {{ item.count }}
          </li>
        </ul>
      </div>
    </div>
    <VueAwesomePaginate
      v-model="selectedPageModel"
      :items-per-page="onPageNumber !== -1 ? onPageNumber : countTotal"
      :max-pages-shown="3"
      :total-items="countTotal"
    >
      <template #prev-button>
        <span>
          <svg>
            <use xlink:href="@/assets/uploads/sprite.svg#arrow"></use>
          </svg>
        </span>
      </template>
      <template #next-button>
        <span>
          <svg>
            <use xlink:href="@/assets/uploads/sprite.svg#arrow"></use>
          </svg>
        </span>
      </template>
    </VueAwesomePaginate>
  </div>
</template>


<style lang="scss" scoped>
/* Pagination */
.data-list__pagination {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: space-between;
}

:deep(.pagination-container) {
  // margin-top: 50px;
  display: flex;
  column-gap: 10px;
  .paginate-buttons {
    height: 40px;
    width: 40px;
    border-radius: 20px;
    cursor: pointer;
    background-color: rgb(242, 242, 242);
    border: 1px solid rgb(217, 217, 217);
    color: black;
    svg {
      height: 100%;
      width: 100%;
    }
    &.next-button {
      transform: rotate(-90deg);
    }
    &.back-button {
      transform: rotate(90deg);
    }
  }
  .paginate-buttons:hover {
    background-color: #d8d8d8;
  }
  
  .active-page {
    background-color: $orange;
    border: 1px solid $orange;
    color: white;
  }
  
  .active-page:hover {
    background-color: $orange;
  }
  
}

/* onPage switch buttons*/
.table-footer__select {
  display: flex;
  align-items: center;
}

.count-list {
  margin-left: 15px;
  ul {
    display: flex;
    align-items: center;
    gap: 10px;
    li {
      background-color: #fee4cb;
      font-size: 12px;
      border-radius: 20px;
      flex-shrink: 0;
      padding: 9px 16px;
      font-weight: 600;
      color: $orange;
      cursor: pointer;
      transition: 0.2s;
      &.active {
        background-color: $orange;
        color: #fff;
      }
      &:hover {
        background-color: $orange;
        color: #fff;
      }
    }
  }
}
</style>
