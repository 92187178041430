<template>
  <teleport to="#app">
    <transition name="fade">
      <div class="modal-edit" v-if="modalIsOpen">
        <div class="modal-edit__wrapper" v-click-away="onClickAway">
          <div class="modal-content">
            <div class="modal-header">
              <button type="button" @click.prevent="$emit('modal-close')">
                <svg>
                  <use xlink:href="@/assets/uploads/sprite.svg#close"></use>
                </svg>
              </button>
            </div>
            <slot name="body">
              <!-- <div class="modal-body">
                Дефолтный контент модального окна
              </div> -->
              <h3 class="modal-title">
                Новый контакт
              </h3>
              <div class="modal-form">
                <ul>
                  <!-- <li>
                    <label>
                      <input type="text" placeholder="Имя" v-model="contactInfo.name">
                    </label>
                  </li> -->
                  <li>
                    <label>
                      <input type="text" placeholder="Фамилия" v-model="lastNameModel">
                    </label>
                  </li>
                  <!-- <li>
                    <multiselect v-model="contactInfo.type" :show-labels="false" :options="positionsOptions" label="type" placeholder="Должность"></multiselect>
                  </li>
                  <li v-if="contactInfo.type && contactInfo.type.type_id === 99">
                    <label>
                      <input type="text" placeholder="Должность" v-model="contactInfo.typeOther">
                    </label>
                  </li>
                  <li>
                    <label>
                      <input type="text" placeholder="Телефон" v-model="contactInfo.phone">
                    </label>
                  </li>
                  <li>
                    <label>
                      <input type="email" placeholder="Email" v-model="contactInfo.email">
                    </label>
                  </li>
                  <li>
                    <multiselect v-model="contactInfo.isGo" :show-labels="false" :options="goOptions" label="text" placeholder="Доступен всем точкам ГО"></multiselect>
                  </li> -->
                </ul>
              </div>
              <!-- <button type="button" class="modal-btn" @click="submitContact">Сохранить</button> -->
              <button type="button" class="modal-btn" @click="submitContact">Сохранить</button>

            </slot>
          </div>
        </div>
      </div>
    </transition>
  </teleport>
</template>

<script>
import { onMounted, onUnmounted, useModel } from 'vue'
// import Multiselect from 'vue-multiselect'

export default {
  name: 'ClientsEditModal',
  // components: { Multiselect },
  emits: [
    'modal-close',
    'save-contact',
    'update:lastName'
  ],
  props: {
    modalIsOpen: {
      type: Boolean,
      required: true,
      default: false
    },
    lastName: {
      type: [String, null],
      required: false,
      default: null
    }
  },
  setup (props, { emit }) {
    const lastNameModel = useModel(props, 'lastName')
    function submitContact () {
      console.log(`lastNameModel= ${lastNameModel.value}`)
      emit('save-contact')
    }
    const onClickAway = () => {
      emit('modal-close')
    }
    const handleKeydown = (event) => {
      if (event.key === 'Escape') {
        emit('modal-close')
      }
    }
    onMounted(() => {
      document.addEventListener('keydown', handleKeydown)
    })

    onUnmounted(() => {
      document.removeEventListener('keydown', handleKeydown)
    })
    return {
      onClickAway,
      submitContact,
      lastNameModel
    }
  }
}
</script>

<style scoped lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
.modal-edit {
  position: fixed;
  overflow: auto;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,.6);
  z-index: 1000;
  display: flex;
  align-items: center;
}

.modal-edit__wrapper {
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  border-radius: 6px;
  min-width: 400px;
  max-width: 500px;
  margin: 0 auto;
  padding: 40px 30px 50px;
  position: relative;
  @media (max-width: 575px) {
    max-width: 95%;
    min-width: 95%;
    padding: 20px 20px 40px;
  }
}

</style>
