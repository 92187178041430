<template>
  <div class="container">
    <div class="page__wrapper">
      <div class="app-link" v-if="appInfo">
        <h3>
          Последняя актуальная версия мобильного приложения только для андроид
        </h3>
        <a :href="appInfo.url">скачать V{{ appInfo.version_title }}</a>
      </div>
      <TheLoader v-if="loading"></TheLoader>
      <div class="charts" v-if="clientCodesArray || productsData || offersData">
        <div class="row">
          <div class="col-md-4">
            <Bar v-if="clientCodesArray" :data="clientCodesArray" :options="chartOptions" />
          </div>
          <div class="col-md-4">
            <Bar v-if="productsData" :data="productsData" :options="chartOptions" />
          </div>
          <div class="col-md-4">
            <Bar v-if="offersData" :data="offersData" :options="chartOptions" />
          </div>
        </div>
      </div>
      <CardHeader>
        <TheTitle>
          <h3>
            Журнал событий
          </h3>
        </TheTitle>
      </CardHeader>
      <EventLog :events-data="events" v-if="events"/>
      <!-- <div class="event-log__pagination" v-if="events.eventsCount > 10"> -->
      <!-- <div class="event-log__pagination" v-if="events && events.eventsCount > 10">
        <div class="table-footer__select">
          <span>Записей на странице: </span>
          <div class="count-list">
            <ul>
              <li v-for="item in countList" :key="item.value" :class="{active: item.value === onPageNumber}" @click="onPageNumber = item.value; changeOnPageNumber(item.value) ">
                {{ item.count }}
              </li>
            </ul>
          </div>
        </div>
        <vue-awesome-paginate
          :total-items="events.eventsCount"
          :items-per-page="onPageNumber !== -1 ? onPageNumber : events.eventsCount"
          :max-pages-shown="3"
          v-model="currentPage"
        >
          <template #prev-button>
            <span>
              <svg>
                <use xlink:href="@/assets/uploads/sprite.svg#arrow"></use>
              </svg>
            </span>
                  </template>
                  <template #next-button>
            <span>
               <svg>
                  <use xlink:href="@/assets/uploads/sprite.svg#arrow"></use>
                </svg>
            </span>
          </template>
        </vue-awesome-paginate>
      </div> -->
      <PaginatedListFooter
        v-if="events"
        v-model:selected-page="currentPage"
        :list-data="events"
        :count-total="events.eventsCount"
        @change-on-page-number="changeOnPageNumber"
      />
    </div>
  </div>
</template>

<script>

import { computed, onMounted, ref, watch } from 'vue'
import axios from 'axios'
import { getTopProductsChart, getTopOffersChart, getTopVisitChart } from '@/api/charts'
import { useUserStore } from '@/stores/UserStore'
import { storeToRefs } from 'pinia'
import TheTitle from '@/components/ui/typography/TheTitle.vue'
import CardHeader from '@/components/ui/card/CardHeader.vue'
import EventLog from '@/components/pages/home/EventLog.vue'
import 'vue-awesome-paginate/dist/style.css'
// import { VueAwesomePaginate } from 'vue-awesome-paginate'
import { Bar } from 'vue-chartjs'
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
} from 'chart.js'
import TheLoader from '@/components/ui/TheLoader.vue'
import PaginatedListFooter from "@/components/pages/PaginatedListFooter.vue";
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

export default {
  name: 'HomeView',
  // props: {
  //   user: { // NOT USED
  //     type: [Object, null],
  //     required: true,
  //     default: null
  //   }
  // },
  components: { TheLoader, CardHeader, TheTitle, EventLog, Bar, PaginatedListFooter },
  setup () {
    const loading = ref(false)
    const events = ref(null)
    const topVisitsData = ref({})
    const topOffersData = ref({})
    const topProductsData = ref({})
    const appInfo = ref(null)
    const userStore = useUserStore()
    const { userToken, getUserPositionId, user } = storeToRefs(userStore)
    const currentPage = ref(1)
    
    const countList = [
      {
        count: 5,
        value: 5
      },
      {
        count: 10,
        value: 10
      },
      {
        count: 15,
        value: 15
      },
      {
        count: 'Все',
        value: -1
      }
    ]
    const onPageNumber = ref(countList[1].value);

    function changeOnPageNumber(entriesNumber) {
      onPageNumber.value = entriesNumber;
      currentPage.value = 1;
      // await getData();
    }

    const clientCodesArray = computed(() => {
      if (topVisitsData.value.items) {
        return {
          labels: topVisitsData.value.items.map(item => item.client_code),
          datasets: [{ label: topVisitsData.value.title, backgroundColor: '#ff8330', data: topVisitsData.value.items.map(item => item.count) }]
        }
      } else return null
    })

    const offersData = computed(() => {
      if (topOffersData.value.items) {
        return {
          labels: topOffersData.value.items.map(item => item.item_code),
          datasets: [{ label: topOffersData.value.title, backgroundColor: '#ff8330', data: topOffersData.value.items.map(item => item.count) }]
        }
      } else return null
    })

    const productsData = computed(() => {
      if (topProductsData.value.items) {
        return {
          labels: topProductsData.value.items.map(item => item.client_code),
          datasets: [{ label: topProductsData.value.title, backgroundColor: '#ff8330', data: topProductsData.value.items.map(item => item.count) }]
        }
      } else return null
    })

    const getAppInfo = async () => {
      try {
        loading.value = true
        const response = await axios('/v1/whatsnew/latest-version', {
          headers: {
            Authorization: `Bearer ${userToken.value}`,
            'Content-Type': 'multipart/form-data'
          }
        })
        if (response.data.success === true) {
          return response.data.data
        } else {
          return null
        }
      } catch (err) {
        throw new Error()
      } finally {
        loading.value = false
      }
    }
    const getData = async () => {
      try {
        loading.value = true
        // const params = new URLSearchParams({ positionId: getUserPositionId.value })
        const params = new URLSearchParams()
        if (user?.value?.position_id && `${user?.value?.position_id}` !== `${getUserPositionId.value}`) {
          params.append('positionId', getUserPositionId.value)
        }
        params.append('page', currentPage.value)
        params.append('onPage', onPageNumber.value)
        const paramsString = params.toString()
        const response = await axios(`/v1/event-client?${paramsString}`, {
          headers: {
            Authorization: `Bearer ${userToken.value}`,
            'Content-Type': 'multipart/form-data'
          }
        })
        if (response.data.success === true) {
          return response.data.data
        } else {
          return null
        }
      } catch (err) {
        throw new Error()
      } finally {
        loading.value = false
      }
    }

    const fetchChartData = async () => {
      try {
        const params = new URLSearchParams({})
        loading.value = true
        topVisitsData.value = await getTopVisitChart(params)
        topProductsData.value = await getTopProductsChart(params)
        topOffersData.value = await getTopOffersChart(params)
        loading.value = false
      } catch (error) {
        loading.value = false
      }
    }
    const chartOptions = ref({
      indexAxis: 'y'
    })

    onMounted(async () => {
      if (getUserPositionId.value) {
        events.value = await getData()
        appInfo.value = await getAppInfo()
        await fetchChartData()
      }
    })
    watch([() => getUserPositionId.value, () => currentPage.value, () => onPageNumber.value], async () => {
      if (getUserPositionId.value || currentPage.value) {
        events.value = await getData()
        appInfo.value = await getAppInfo()
        await fetchChartData()
      }
    })
    return {
      events,
      currentPage,
      loading,
      chartOptions,
      clientCodesArray,
      offersData,
      productsData,
      appInfo,
      countList,
      onPageNumber,
      changeOnPageNumber,
    }
  }
}
</script>

<style lang="scss" scoped>
.charts {
  margin-bottom: 40px;
}

/* Pagination */
.event-log__pagination {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: space-between;
}

:deep(.pagination-container) {
  // margin-top: 50px;
  display: flex;
  column-gap: 10px;
  .paginate-buttons {
    height: 40px;
    width: 40px;
    border-radius: 20px;
    cursor: pointer;
    background-color: rgb(242, 242, 242);
    border: 1px solid rgb(217, 217, 217);
    color: black;
    svg {
      height: 100%;
      width: 100%;
    }
    &.next-button {
      transform: rotate(-90deg);
    }
    &.back-button {
      transform: rotate(90deg);
    }
  }
  .paginate-buttons:hover {
    background-color: #d8d8d8;
  }
  
  .active-page {
    background-color: $orange;
    border: 1px solid $orange;
    color: white;
  }
  
  .active-page:hover {
    background-color: $orange;
  }
  
}

.app-link {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 34px;
  gap: 15px;
  @media (max-width: 575px) {
   flex-direction: column;
    align-items: flex-start;
  }
  h3 {
    font-size: 18px;
    line-height: 24px;
    font-weight: 700;
  }
  a {
    background-color: $orange;
    color: #fff;
    text-transform: uppercase;
    border: none;
    border-radius: 5px;
    padding: 12px 15px;
    font-size: 14px;
    display: block;
  }
}

.table-footer__select {
  display: flex;
  align-items: center;
}

.count-list {
  margin-left: 15px;
  ul {
    display: flex;
    align-items: center;
    gap: 10px;
    li {
      background-color: #fee4cb;
      font-size: 12px;
      border-radius: 20px;
      flex-shrink: 0;
      padding: 9px 16px;
      font-weight: 600;
      color: $orange;
      cursor: pointer;
      transition: 0.2s;
      &.active {
        background-color: $orange;
        color: #fff;
      }
      &:hover {
        background-color: $orange;
        color: #fff;
      }
    }
  }
}
</style>
