import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import FingerView from '@/views/FingerView.vue'
import AuthView from '@/views/auth/AuthView.vue'
import { getCookie } from '@/utilits/getCookie'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/visit-fact',
    name: 'visit-fact',
    component: () => import('../views/visits/VisitsFactsView.vue')
  },
  {
    path: '/visit-fact/:id', // without /:positionId
    name: 'visit-card',
    component: () => import('../views/visits/VisitsCardView.vue')
  },
  {
    path: '/visit-plan',
    name: 'visit-plan',
    component: () => import('../views/visits/VisitsPlanView.vue')
  },
  {
    path: '/visit-plan/:id',
    name: 'visit-plan-card',
    component: () => import('../views/visits/VisitPlanView.vue')
  },
  // {
  //   path: '/visit-fact/:id/:positionId',
  //   name: 'visit-card',
  //   component: () => import('../views/visits/VisitsCardView.vue')
  // },
  {
    path: '/auth',
    name: 'auth',
    component: AuthView
  },
  {
    path: '/calls',
    name: 'calls',
    component: () => import('../views/CallsView.vue')
  },
  {
    path: '/contacts',
    name: 'contacts',
    component: () => import('../views/contacts/ContactsView.vue')
  },
  {
    path: '/contacts/:id',
    name: 'contacts-card',
    component: () => import('../views/contacts/ContactsCardView.vue')
  },
  {
    path: '/clients/:id',
    name: 'clients-card',
    component: () => import('../views/clients/ClientsCardView.vue')
  },
  {
    path: '/clients',
    name: 'agents',
    component: () => import('../views/clients/ClientsView.vue')
  },
  {
    path: '/offers',
    name: 'offers',
    component: () => import('../views/OffersView.vue')
  },
  {
    path: '/task/add',
    name: 'task-add',
    component: () => import('../views/tasks/TaskCreateView.vue')
  },
  {
    path: '/tasks/edit/:id',
    name: 'edit-task',
    component: () => import('../views/tasks/TaskCreateView.vue'),
    beforeEnter(to, from){
      // Preserve route path from which we have opened Edit Task page. 
      to.meta.navigateFrom = from.path
    }
  },
  {
    path: '/task',
    name: 'task',
    component: () => import('../views/tasks/TasksListView.vue')
  },
  {
    path: '/task/:id',
    name: 'task-card',
    component: () => import('../views/tasks/TaskItemView.vue')
  },
  {
    path: '/reports/:type',
    name: 'reports',
    component: () => import('../views/reports/ReportView.vue')
  },
  {
    path: '/index.html',
    name: 'finger',
    component: FingerView
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'error',
    component: () => import('../views/ErrorView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  scrollBehavior (to, from, savedPosition) {
    // Если есть hash, прокручиваем к элементу по нему
    if (to.hash) {
      return { el: to.hash }
    }

    // Если есть savedPosition, возвращаем его же (переход назад/вперёд)
    if (savedPosition) {
      return savedPosition
    }

    // Если оба маршрута в мета свойствах имеют saveScrollPosition - не меняем положение
    if (to.meta.saveScrollPosition && from.meta.saveScrollPosition) {
      return false
    }

    // По умолчанию возвращаемся в начало
    return { left: 0, top: 0 }
  },

  routes
})

router.beforeEach(async (to, from) => {
  const token = getCookie('sfa_user_token')
  const protectedRoutes = [
    'home',
    'calls',
    'contacts',
    'agents',
    'offers',
    'task',
    'task-card',
    'edit-task',
  ]

  const authPath = [
    'auth'
  ]

  if (token && authPath.includes(to.name)) {
    return { name: 'home' }
  }

  if (!token && protectedRoutes.includes(to.name)) {
    return { name: 'auth' }
  }

  if (to.name === 'finger' && from.name === 'home') {
    return false
  }
})

export default router
