import { defineStore } from 'pinia'
import { ref } from 'vue'
import axios from 'axios'
import { getCookie } from '@/utilits/getCookie'

export const useUsersListStore = defineStore('usersList', () => {
  const usersList = ref(null)

  const fetchUsers = async () => {
    try {
      const response = await axios.get('/v2/user/users-list', {
        headers: {
          Authorization: `Bearer ${getCookie('sfa_user_token')}`
        }
      })
      if (response.data.success === true) {
        usersList.value = response.data.data
      }
    } catch (err) {
      throw new Error()
    }
  }

  return {
    fetchUsers,
    usersList
  }
})
