<template>
  <teleport to="#app">
    <transition name="fade">
      <div class="modal-edit" v-if="modalIsOpen">
        <div class="modal-edit__wrapper" v-click-away="onClickAway">
          <div class="modal-content">
            <div class="modal-header">
              <button type="button" @click.prevent="$emit('modal-close')">
                <svg>
                  <use xlink:href="@/assets/uploads/sprite.svg#close"></use>
                </svg>
              </button>
            </div>
            <div class="modal-body">
              <VueMultiselect
                v-model="selectedUser"
                :options="users"
                :close-on-select="true"
                :clear-on-select="false"
                placeholder="Выберите сотрудника"
                track-by="first_name"
                selectLabel="Выбрать"
                deselectLabel="Удалить"
                openDirection="bottom"
                :custom-label="fullName"
              >
                <template v-slot:noResult>
                  <span>По вашему запросу ничего не найдено...</span>
                </template>
                <template #singleLabel="{ option }">
                  <div>{{ option.last_name }} {{ option.first_name }} {{ option.middle_name }}</div>
                </template>
                <template #option="{ option }">
                  <div>{{ option.last_name }} {{ option.first_name }} {{ option.middle_name }}</div>
                </template>
              </VueMultiselect>
              <button type="button" class="modal-btn" @click.prevent="changePosition">Сменить позицию</button>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </teleport>
</template>

<script>
import { onMounted, onUnmounted, ref, watch } from 'vue'
import VueMultiselect from 'vue-multiselect'
import { storeToRefs } from 'pinia'
import { useUserStore } from '@/stores/UserStore'
export default {
  name: 'ChangePositionModal',
  emits: ['modal-close'],
  components: { VueMultiselect },
  props: {
    users: {
      type: [Array, null],
      required: true,
      default: null
    },
    modalIsOpen: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  setup (props, { emit }) {
    const selectedUser = ref(null)
    const userStore = useUserStore()
    const { getUserPositionId, user } = storeToRefs(userStore)
    const changePosition = () => {
      if (selectedUser.value && selectedUser.value.position_id) {
        userStore.setUserPosition(selectedUser.value.position_id, selectedUser.value.last_name, selectedUser.value.first_name)
        emit('modal-close')
        // selectedUser.value = null
      }
    }
    // eslint-disable-next-line camelcase
    const fullName = ({ last_name, first_name, middle_name }) => {
      // eslint-disable-next-line camelcase
      return `${last_name} – ${first_name} - ${middle_name}`
    }
    const onClickAway = () => {
      emit('modal-close')
    }
    const handleKeydown = (event) => {
      if (event.key === 'Escape') {
        emit('modal-close')
      }
    }
    onMounted(() => {
      document.addEventListener('keydown', handleKeydown)
    })

    onUnmounted(() => {
      document.removeEventListener('keydown', handleKeydown)
    })

    watch([() => getUserPositionId.value], async () => {
      if (getUserPositionId.value) {
        if (`${user?.value?.position_id}` === `${getUserPositionId.value}`) {
          selectedUser.value = null;
        }
      }
    })

    return {
      selectedUser,
      changePosition,
      fullName,
      onClickAway
    }
  }

}

</script>

<style scoped lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
.modal-edit {
  position: fixed;
  overflow: auto;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,.6);
  z-index: 1000;
  display: flex;
  align-items: center;
}
.modal-body {
  margin-top: 40px;
  button {
    width: 100%;
  }
}
.modal-edit__wrapper {
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  border-radius: 6px;
  min-width: 400px;
  max-width: 500px;
  margin: 0 auto;
  padding: 20px 30px 40px;
  position: relative;
  @media (max-width: 575px) {
    max-width: 95%;
    min-width: 95%;
    padding: 20px 20px 40px;
  }
}

</style>
